import { computed, toRef } from 'vue'
import { useNuxtApp } from '#app'
import { useAPI, useCacheData, useCacheWrapper, useSharedPromise } from '#imports'
import { useDevice } from '@/composables/device'
import { useProducts } from '@/stores/products'

import type { Product, ProductDetails } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'
import type { AvailabilityDetails, ReplacementProduct } from '@/modules/nuxt-api/models/Product'

type State = {
  currentProduct: Product.AsObject | undefined
  currentSimilarProducts: Product.AsObject[]
  currentAvailability: Partial<AvailabilityDetails> | undefined
  currentProductCode: Product.AsObject['code'] | undefined
}

export function useProductStore () {
  const nuxtApp = useNuxtApp()
  const api = useAPI()

  const state = useHydrationStore<State>('product-store', {
    currentProduct: undefined,
    currentSimilarProducts: [],
    currentAvailability: undefined,
    currentProductCode: undefined
  })

  function clearCurrentData () {
    state.value.currentProduct = undefined
    state.value.currentSimilarProducts = []
  }

  async function getProduct (code: Product.AsObject['code'], withDetails = true) {
    if (!code) {
      return { product: undefined, details: undefined, replacement: undefined }
    }

    state.value.currentProductCode = code

    const { value, addToCache } = await useCacheData<
      [
        Product.AsObject | undefined,
        ProductDetails.AsObject | undefined,
        ReplacementProduct | undefined
      ]
    >(['product', code])

    let currentProduct: Product.AsObject | undefined, currProductDetails: ProductDetails.AsObject | undefined, currProductReplacement

    if (value) {
      currentProduct = value[0]
      currProductDetails = value[1]
      currProductReplacement = value[2]
    } else {
      const { product, details, replacement } = await nuxtApp.runWithContext(() =>
        useSharedPromise(['product', code], async () => {
          const { requireProduct } = api.product()

          return await requireProduct(code, withDetails)
        })
      )

      currentProduct = product as Product.AsObject
      currProductDetails = details
      currProductReplacement = replacement

      if (product && currProductDetails) {
        await addToCache([product, currProductDetails, currProductReplacement])
      }
    }

    state.value.currentProduct = currentProduct

    return {
      product: currentProduct,
      details: currProductDetails,
      replacement: currProductReplacement
    }
  }

  async function getReviewsForProduct (id: number) {
    const key = ['product-reviews', id.toString()]
    const { getProductReviews } = api.reviews()

    return useSharedPromise(key, () => useCacheWrapper(key, async () => {
      const [reviews] = await getProductReviews(id, {
        size: 100,
        cursor: '0'
      })

      return reviews
    }))
  }

  async function getProductVintagesExpertRates (code: Product.AsObject['code']) {
    const key = ['product-vintages', code]
    const { getProductVintagesExpertRates } = api.product()

    return useCacheWrapper(key, async () => {
      return getProductVintagesExpertRates(code)
    })
  }

  async function getProductShortAvailability (variationIdsList: number[] = [], id?: number) {
    if (id) {
      const idsList = Array.from(new Set([...variationIdsList, id]))
      const availabilityData = await useProducts().getProductAvailability(idsList)
      state.value.currentAvailability = { availability: availabilityData[id] }
      return state.value.currentAvailability
    }

    return undefined
  }

  async function getProductDetailedAvailability () {
    const { isCrawler } = useDevice()
    const code = state.value.currentProductCode

    if (isCrawler.value || !code) {
      return undefined
    }

    const { getProductDetailedAvailability } = api.product()
    state.value.currentAvailability = await getProductDetailedAvailability(code).catch(() => undefined)

    return state.value.currentAvailability
  }

  function updateSimilarProducts (products?: Product.AsObject[]) {
    if (products?.length) {
      state.value.currentSimilarProducts = products
    }
  }

  function updateCurrentProduct (
    code: string,
    product?: Product.AsObject,
    availability?: Partial<AvailabilityDetails> | undefined,
    similarProducts?: Product.AsObject[]
  ) {
    if (code !== state.value.currentProductCode) {
      clearCurrentData()
      state.value.currentProductCode = code
    }

    if (product) {
      state.value.currentProduct = product
    }

    if (availability) {
      state.value.currentAvailability = availability
    }

    updateSimilarProducts(similarProducts)
  }

  const hasSimilarAboveFive = computed(() => {
    return (state.value.currentSimilarProducts?.length ?? 0) > 5
  })

  return {
    clearCurrentData,
    getProduct,
    getReviewsForProduct,
    getProductShortAvailability,
    getProductDetailedAvailability,
    updateCurrentProduct,
    hasSimilarAboveFive,
    getProductVintagesExpertRates,
    updateSimilarProducts,

    currentProduct: toRef(state.value, 'currentProduct'),
    currentSimilarProducts: toRef(state.value, 'currentSimilarProducts'),
    currentAvailability: toRef(state.value, 'currentAvailability'),
    currentProductCode: toRef(state.value, 'currentProductCode')
  }
}
